var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text text-h6 pb-0"},[_vm._v(" Total ")]),_c('v-card-text',{staticClass:"primary--text text-17 text-left"},[_vm._v(" "+_vm._s(_vm.totalTransactions || 0)+" | "+_vm._s(_vm.helpers.formatAmount(_vm.totalTransactionsAmount) || 0)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text text-h6 pb-0"},[_vm._v(" Successful ")]),_c('v-card-text',{staticClass:"primary--text text-17 text-left"},[_vm._v(" "+_vm._s(_vm.successfulTransactions || 0)+" | "+_vm._s(_vm.helpers.formatAmount(_vm.successfulTransactionsAmount) || 0)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text text-h6 pb-0"},[_vm._v(" Failed ")]),_c('v-card-text',{staticClass:"primary--text text-17 text-left"},[_vm._v(" "+_vm._s(_vm.failedTransactions || 0)+" | "+_vm._s(_vm.helpers.formatAmount(_vm.failedTransactionsAmount) || 0)+" ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{staticClass:"primary--text text-h6",attrs:{"flat":""}},[_vm._v(" All Transactions ")]),_c('search-and-filter',{attrs:{"placeholder":_vm.placeholder}}),_c('v-data-table',{staticClass:"row-pointer",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.transactions,"loading":_vm.loadTransactions,"items-per-page":_vm.pagination.limit},on:{"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.numbers",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.formatAmount(item.amount))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.formatDate(item.createdAt))+" ")]}},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reference || item.requestRef)+" ")]}}])})],1)],1)],1),(_vm.pagination.total > _vm.pagination.limit)?_c('div',{staticClass:"my-4 d-flex justify-end"},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.pagination.total / _vm.pagination.limit),"total-visible":_vm.pagination.total < 3 ? 2 : 6},on:{"input":_vm.getNext},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e(),_c('transaction-details',{ref:"transactionDetails",attrs:{"transaction":_vm.transaction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }